<template>
  <div>
    <c-table
      ref="table"
      title="규제 DB 업로드 목록"
      tableId="impr01"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'btn1' 
          && (props.row['chmUploadStateCd'] === 'CUS0000015'
            || props.row['chmUploadStateCd'] === 'CUS0000025')">
          <!-- 적용대기, 적용오류인 경우 -->
          <q-btn-group outline >
            <c-btn v-if="editable" label="적용" icon="inventory" color="blue-custom" @btnClicked="applyChem(props.row)" />
          </q-btn-group>
        </template>
        <template v-else-if="col.name === 'btn2' 
          && props.row['chmUploadStateCd'] !== 'CUS0000020' 
          && props.row['chmUploadStateCd'] !== 'CUS0000001'">
          <!-- 업로드중, 적용완료를 제외하고 -->
          <q-btn-group outline >
            <c-btn v-if="editable" label="삭제" icon="delete" @btnClicked="deleteUpload(props.row)" />
          </q-btn-group>
        </template>
        <template v-else-if="col.name === 'chmUploadStateName'">
          <q-btn  
            v-if="props.row['chmUploadStateCd'] === 'CUS0000015' || props.row['chmUploadStateCd'] === 'CUS0000020'"
            class="tableinnerBtn"
            flat
            align="center"
            color="blue-6"
            :label="props.row[col.name]"
            @click.stop="linkClick(props.row)" />
          <template v-else>
            {{props.row[col.name]}}
          </template>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'regulDbUpload',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'updateDt',
            field: 'updateDt',
            label: '업로드 일시',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'chmUploadStateName',
            field: 'chmUploadStateName',
            label: '상태',
            align: 'center',
            style: 'width:150px',
            sortable: true,
            type: 'custom',
          },
          // {
          //   name: 'cnt',
          //   field: 'cnt',
          //   label: '업로드 건 수',
          //   child: [
          //     {
          //       name: 'addCnt',
          //       field: 'addCnt',
          //       label: '추가',
          //       align: 'right',
          //       style: 'width:120px',
          //       sortable: true,
          //       type: 'cost',
          //     },
          //     {
          //       name: 'updateCnt',
          //       field: 'updateCnt',
          //       label: '수정',
          //       align: 'right',
          //       style: 'width:120px',
          //       sortable: true,
          //       type: 'cost',
          //     },
          //     {
          //       name: 'deleteCnt',
          //       field: 'deleteCnt',
          //       label: '삭제',
          //       align: 'right',
          //       style: 'width:120px',
          //       sortable: true,
          //       type: 'cost',
          //     },
          //     {
          //       name: 'noChangeCnt',
          //       field: 'noChangeCnt',
          //       label: '변동없음',
          //       align: 'right',
          //       style: 'width:120px',
          //       sortable: true,
          //       type: 'cost',
          //     },
          //     {
          //       name: 'totalCnt',
          //       field: 'totalCnt',
          //       label: 'TOTAL',
          //       align: 'right',
          //       style: 'width:120px',
          //       sortable: true,
          //       type: 'cost',
          //     },
          //   ]
          // },
          {
            name: 'failContents',
            field: 'failContents',
            label: '실패내용',
            align: 'left',
            style: 'width:450px',
            sortable: true,
            type: 'html',
          },
          {
            name: 'btn1',
            field: 'btn1',
            label: '적용',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'custom',
          },
          {
            name: 'btn2',
            field: 'btn2',
            label: '삭제',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'custom',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      acceptUrl: '',
      deleteUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.db.regulDbUpload.list.url
      this.acceptUrl = transactionConfig.chm.db.regulDbUpload.accept.url
      this.deleteUrl = transactionConfig.chm.db.regulDbUpload.delete.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    openPop() {
      this.popupOptions.title = '규제DB업로드'; // 규제DB업로드
      this.popupOptions.target = () => import(`${'./regulDbUploadDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUploadPopup;
    },
    closeUploadPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    linkClick(row) {
      switch (row.chmUploadStateCd) {
        case 'CUS0000015': // 적용대기
        case 'CUS0000020': // 적용완료
          this.popupOptions.title = '변경 이력'; // 변경 이력
          this.popupOptions.target = () => import(`${'./regulDbUploadHistory.vue'}`);
          this.popupOptions.param = {
            chmRegulDbUploadId: row.chmRegulDbUploadId
          }
          this.popupOptions.visible = true;
          this.popupOptions.closeCallback = this.closeHistoryPopup;
          break;
        case 'CUS0000005': // 업로드실패
        case 'CUS0000010': // 적용불가
        case 'CUS0000025': // 적용오류
          break;
        default:
          // nothing...
          break;
      }
    },
    closeHistoryPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    applyChem(row) {
      this.$http.url = this.acceptUrl;
      this.$http.type = 'PUT';
      this.$http.param = {
        chmRegulDbUploadId: row.chmRegulDbUploadId
      }
      this.$http.request((_result) => {
        if (_result.data === 1) {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '적용도중 오류가 발생하였습니다.', // 적용도중 오류가 발생하였습니다.
            type: 'warning', // success / info / warning / error
          });
        }
        this.getList();
      },);
    },
    deleteUpload(row) {
      this.$http.url = this.$format(this.deleteUrl, row.chmRegulDbUploadId);
      this.$http.type = 'DELETE';
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getList();
      },);
    },
  }
};
</script>
